<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">专家信息</el-breadcrumb-item>

        </el-breadcrumb>
      </div>
    </div>
    <!--添加专家按钮-->
    <div v-if="GET_USERID === '828gXLp1'">
      <el-button size="mini" style="float: right;margin-top: -1%;margin-right: 2.5%" type="success"
                 @click="addExpertMessageDio()">添加专家信息
      </el-button>
    </div>

    <!--专家选项-->
    <div>
      <!--  选项div-->
      <div style="position: absolute;display: flex;top: 12%;left: 8%">
        <div>
          <div v-if="now_choose !== '内部专家'" class="font_div1" @click="changeChoose('内部专家', '0')">
            内部专家
          </div>
          <div v-if="now_choose === '内部专家'" class="font_div2">
            内部专家
          </div>
        </div>
        <div>
          <div v-if="now_choose !== '外部专家'" class="font_div1" @click="changeChoose('外部专家', '1')">
            外部专家
          </div>
          <div v-if="now_choose === '外部专家'" class="font_div2">
            外部专家
          </div>
        </div>
        <div>
          <div v-if="now_choose !== '隧道专家'" class="font_div1" @click="changeChoose('隧道专家', '2')">
            隧道专家
          </div>
          <div v-if="now_choose === '隧道专家'" class="font_div2">
            隧道专家
          </div>
        </div>
        <div>
          <div v-if="now_choose !== '外单位专家'" class="font_div1" @click="changeChoose('外单位专家', '3')">
            外单位专家
          </div>
          <div v-if="now_choose === '外单位专家'" class="font_div2">
            外单位专家
          </div>
        </div>
      </div>
    </div>

    <!--专家图片-->
    <div style="width: 80%;position: absolute;display: flex;top: 20%;left: 9.5%;flex-wrap: wrap">
      <div v-for="item in list">
        <img :src="item.imageUrl" style="width: 15vh;height: 200px;margin-right: 5vh" @click="getmessage(item)"
             @contextmenu.prevent="delmessage(item)"/>
        <div style="text-align: center;width: 15vh;font-size: 1.3em;margin-top: 5%;">
          {{ item.expertName }}
        </div>
        <div style="text-align: center;width: 15vh;font-size: 1em;margin-top: 5%;">
          {{ item.expertProfession }}
        </div>
      </div>
    </div>


    <!--专家信息-->
    <div v-if="show" style="position: absolute;width: 141vh;height: 88vh;background-color: #FFFFFF;top: 12%;left: 8%">
      <div style="position: absolute;top: 0.5vh;right: -11%" @click="close_dialog()">
        <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/expert_close.png" style="width: 10%">
      </div>
      <div>
        <!--  图片、专业信息-->
        <div style="display: flex;margin-left: 5%">
          <div>
            <img :src="message.imageUrl" style="width: 250px;height: 300px;margin-top: 30%"/>
          </div>
          <div
              style="width: 80%;height: 49%;margin-left: 5%;margin-top: 5%;font-size: 1.3em;color: black;font-family: '苹方', serif">
            <div style="margin-bottom: 1%;color: #3573e6;font-weight: bolder;font-size: 1.6em">
              {{ message.expertName }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              专业：{{ message.expertProfession }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              学位/学历：{{ message.degreeEducation }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              毕业院校：{{ message.graduateSchool }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              职业：{{ message.occupation }}
            </div>
            <div style="margin-bottom: 2%;font-weight: bolder;">
              职务：{{ message.post }}
            </div>
            <div style="font-weight: bolder;">
              主要成就：{{ message.majorAchievements }}
            </div>
          </div>
        </div>
        <div style="margin-top: 5%;margin-left: 5%;font-family: '苹方', serif">
          <div style="font-size: 2em;font-weight: bolder">
            详细介绍
          </div>
          <div style="font-size: 1.3em;line-height: 4vh;color: black;margin-top: 3%;font-weight: bolder">
            {{ message.briefIntroduction }}
          </div>
        </div>
      </div>
    </div>

    <!--  删除信息提示框-->
    <el-dialog
        v-model="deldialogVisible"
        title="删除确认"
        width="30%"
    >
      <span style="font-size: 1.5em;color: black">{{ tooltips }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="deldialogVisible = false">取消</el-button>
        <el-button type="primary" @click="delExpertMessage()"> 确认 </el-button>
      </span>
      </template>
    </el-dialog>

    <AddExpert v-on:NotshowExpert="NotshowExpert()" v-if="addExperDialog" addExperDialog="addExperDialog"></AddExpert>

  </div>
</template>

<script>
import Header from "@/views/header1";
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";
import {uuid} from "@/utils/time"
import AddExpert from "@/views/expert_consult/assembly/addExpert";
export default {
  inject: ['reload'],
  components: {AddExpert, Header},
  name: "expert_info",
  data() {
    return {
      list: [],
      dialogVisible: false,
      message: '',
      tip: '',
      show: false,
      deldialogVisible: false,
      now_choose: '',
      dataup: {
        file: '',
        userid: ''
      },

      tooltips: '',
      delId: '',
      addExpertMessageVisible: false,
      formMessage: {
        expertName: '',
        expertProfession: '',
        graduateSchool: '',
        degreeEducation: '',
        post: '',
        occupation: '',
        representativeWork: '',
        majorAchievements: '',
        briefIntroduction: '',
        expertFlag: '',
      },
      expertFlags: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        }
      ],
      fileList: [],
      addExperDialog: false
    }

  },
  created() {
    if (this.$route.params.key === '0') {
      this.now_choose = '内部专家';
    }
    if (this.$route.params.key === '1') {
      this.now_choose = '外部专家';
    }
    if (this.$route.params.key === '2') {
      this.now_choose = '隧道专家';
    }
    if (this.$route.params.key === '3') {
      this.now_choose = '外单位专家';
    }
    this.getExperts(this.$route.params.key);
  },
  computed: {
    ...mapGetters(['GET_USERID'])
  },
  methods: {
    NotshowExpert(val){
      this.addExperDialog = val;
    },
    getExperts(val) {
      this.axios.post('/expertMessage/getExpertMessage', (response) => {
        this.list = response.obj;
      }, {
        flag: val
      })
    },
    getmessage(val) {
      this.message = val;
      this.show = true;
      window.scrollTo(0, 0);
    },
    addExpertMessageDio() {
      this.addExperDialog = true;
    },
    delmessage(val) {
      this.tooltips = '是否确认删除 ' + val.expertName + ' 专家的信息?';
      this.delId = val.id;
      this.deldialogVisible = true;
    },
    delExpertMessage() {
      this.axios.post("/expertMessage/delExpertMessage", (response) => {
        if (response.obj) {
          this.reload();
        } else {
          ElMessage.error("删除失败，请联系科信部!");
        }
      }, {
        id: this.delId * 1
      })
    },
    changeChoose(val, falg) {
      this.now_choose = val;
      this.getExperts(falg);
    },
    close_dialog() {
      this.show = false;
      this.message = '';
    }
  }
}

</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}

.font_div1 {
  width: 35vh;
  height: 5vh;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 5vh;
  font-family: '苹方', serif;
  font-weight: bolder;
  letter-spacing: 3px;
  border: 1px #d2d2d2 solid;
}

.font_div2 {
  width: 35vh;
  height: 5vh;
  color: #3573e6;
  background-color: #FFFFFF;
  text-align: center;
  line-height: 5vh;
  font-family: '苹方', serif;
  font-weight: bolder;
  letter-spacing: 3px;
  border: 1px #3573e6 solid;
}
</style>